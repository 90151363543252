<template>
  <div>
    <!--      头部      -->
    <div class="iq-top-navbar">
      <nav class="navbar navbar-expand-lg">
        <el-breadcrumb separator-class="el-icon-arrow-right" style="padding: 0 15px">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>消息管理</el-breadcrumb-item>
        </el-breadcrumb>
        <head-center></head-center>
      </nav>
    </div>
    <!--    内容    -->
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top">
          <div class="iq-search-bar">
            <el-form :model="searchForm" :inline="true">
              <el-form-item>
                <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="姓名" @keyup.enter.native="search" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="search()">搜索</el-button>
                <el-button type="primary" size="small" @click="AddBtn" plain>发布消息</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <el-tabs v-model="activeIndex" @tab-click="handleClick">
          <el-tab-pane :label="item.label" :name="item.value" v-for="(item, index) in tabList" :key="index">
            <el-table :data="MessageData" stripe style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }">
              <el-table-column prop="title" label="消息标题"> </el-table-column>
              <el-table-column width="300" prop="content" label="内容" :show-overflow-tooltip="true" align="center"> </el-table-column>
              <el-table-column prop="createUser.userName" label="发布人" align="center"> </el-table-column>
              <el-table-column prop="createTime" label="发布时间" align="center">
                <template slot-scope="scope">{{ scope.row.createTime | timefilters }}</template>
              </el-table-column>
              <el-table-column label="操作" width="180" align="center">
                <template slot-scope="scope">
                  <span class="info optionBtn" @click="EditUserBtn(scope.row)">详情</span>
                  <span v-if="activeIndex == '2'" class="danger optionBtn" @click="RemoveMessage(scope.$index)">删除 </span>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex" :total="DataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!--    发布消息    -->
    <el-drawer title="发布消息" direction="ttb" size="100%" :visible.sync="releaseMesdialog">
      <div class="addDrawerBox">
        <div class="addDrawerwrap">
          <el-scrollbar class="left-nav-wrap" style="height: 100%">
            <el-form :model="platformData" ref="platformData" :label-width="formLabelWidth">
              <el-form-item label="消息名称">
                <el-input v-model="platformData.title" ref="title"></el-input>
              </el-form-item>
              <el-form-item label="消息内容">
                <el-input ref="content" type="textarea" :rows="2" placeholder="请输入内容" v-model="platformData.content" style="margin-top: 15px"> </el-input>
              </el-form-item>
              <el-form-item label="附件">
                <el-upload class="upload-demo" :headers="headers" :action="FileUploadUrl" :on-remove="handleRemove" multiple :file-list="fileList" :before-upload="beforeAvatarUpload" :on-success="handleUpload">
                  <el-button size="small" type="primary">点击上传</el-button>
                </el-upload>
              </el-form-item>
              <el-form-item v-if="platformData.resource == 1">
                <!-- <el-form-item label="查询用户">
                  <el-input v-model="herbalDate.keyword" placeholder="用户名字模糊查询...." style="width: 300px; margin-left: 20px; margin-right: 20px"></el-input>
                  <el-button type="primary" @click="herbalSearch">查询</el-button>
                </el-form-item> -->
                <el-form-item label="选择用户">
                  <template>
                    <el-transfer v-model="platformData.herbalIdArr" :data="DoctorData" :props="{ key: 'id', label: 'userName' }" :titles="['查询用户', '已选用户']" style="margin: 20px" filterable :filter-method="filterMethod" filter-placeholder="请输入用户名"> </el-transfer>
                  </template>
                </el-form-item>
                <el-form-item style="margin-left: 35%">
                  <el-button @click="CancelMessage">取 消</el-button>
                  <el-button @click="SaveMessage" type="primary" style="margin-left: 50px">确 定 </el-button>
                </el-form-item>
              </el-form-item>
            </el-form>
          </el-scrollbar>
        </div>
      </div>
    </el-drawer>
    <!--    详情    -->
    <!--    详情    -->
    <el-drawer class="box" title="消息详情" :visible.sync="detaildrawer" :direction="direction" wrapperClosable>
      <div class="drawer-box">
        <div class="top-title">
          <span>发布者：{{ detailData.createUserName || '' }}</span>
          <span>发布时间：{{ detailData.createTime | timefilters }}</span>
        </div>
        <div class="reviceUser-title">
          接收者：
          <span v-for="(item, index) in detailData.reviceNameList" :key="index"> {{ item || '' }}&nbsp; </span>
        </div>
        <h2 class="title">{{ detailData.title }}</h2>
        <p>{{ detailData.content }}</p>
        <div class="item-img" v-if="detailData.atta.length > 0">
          <img v-for="(item, index) in detailData.atta" :src="Services.Authorization + item.filePath" @click="handleImagePreview(item)" />
        </div>
        <el-dialog :visible.sync="Image.dialogVisible">
          <img width="100%" :src="Services.Authorization + Image.dialogImageUrl" alt="" />
        </el-dialog>
        <!-- <el-input ref="content" type="textarea" :rows="2" disabled v-model="detailData.content"
                    style="margin-top: 15px;" /> -->
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { Message } from '../../../components/HospitalDomain/Message'
import { User } from '../../../components/HospitalDomain/User'
export default {
  name: 'Messages',
  computed: {
    headers() {
      return {
        Authorization: 'Bearer' + ' ' + JSON.parse(sessionStorage.getItem(this.ClientId + 'Token')).access_token,
      }
    },
  },
  data() {
    var MessageDomainTemp = new Message(this.TokenClient, this.Services.Authorization)
    var UserTemp = new User(this.TokenClient, this.Services.Authorization)
    return {
      MessageData: [],
      tabList: [
        {
          label: '系统通知',
          value: '1',
        },
        {
          label: '机构消息',
          value: '2',
        },
      ],
      userInfo: JSON.parse(sessionStorage.getItem('userInfo')),
      releaseMesdialog: false,
      platformData: {
        name: '',
        content: '',
        resource: 1,
        categoryId: -1,
      },
      PageIndex: 1,
      PageTotal: 1,
      PageSize: 0,
      DataTotal: 1,
      searchForm: {
        statrTime: '',
        endTime: '',
        keyWord: '',
        datepicker: '',
      },
      FileUploadUrl: `${this.Services.Authorization}/api/File/upload`,
      formLabelWidth: '90px',
      herbalDate: [],
      transferDate: [],
      MessageDomain: MessageDomainTemp,
      DoctorData: [],
      UserDomain: UserTemp,
      fileList: [],
      uploadfileList: [],
      detaildrawer: false,
      direction: 'rtl',
      detailData: {
        createUser: {
          userName: '',
        },
        category: {
          name: '',
        },
        atta: [],
      },
      Image: {
        //单击图片上传控件中的图片时，放大显示
        dialogVisible: false,
        dialogImageUrl: '',
      },
      activeIndex: '1',
    }
  },
  mounted() {
    this.getAllMessage()
  },
  methods: {
    handleClick(tab, event) {
      this.PageIndex = 1
      this.MessageData = []
      this.PageSize = 1
      this.PageTotal = 0
      this.DataTotal = 0
      // this.activeIndex = tab.index
      this.getAllMessage()
    },
    handleImagePreview(file) {
      this.Image.dialogImageUrl = file.filePath
      this.Image.dialogVisible = true
    },
    beforeAvatarUpload(file) {
      console.log()
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 20

      if (!isJPG) {
        this.$message.error('图片格式不正确')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过20MB!')
      }
      return isJPG && isLt2M
    },
    AddBtn() {
      this.releaseMesdialog = true
      this.DoctorData = []
      this.fileList = []
      this.uploadfileList = []
      this.platformData = {
        name: '',
        content: '',
        resource: 1,
        categoryId: -1,
        herbalIdArr: [],
      }
      this.herbalSearch()
    },
    EditUserBtn(item) {
      var _this = this
      _this.MessageDomain.GetMessageDetail(
        item.id,
        function (data) {
          _this.detailData = data.data
          _this.detaildrawer = true
        },
        function (error) {
          console.log(error)
        }
      )
    },
    RemoveUser(item) {},
    handleRemove(file, fileList) {
      var _this = this
      const filePath = file.response[0].completeFilePath
      //2.从pics数组中找到图片对应的索引值
      //形参‘x’，是数组pic里的每一项
      const i = _this.uploadfileList.findIndex((x) => x.filePath == filePath)
      //调用数组的splice方法，把图片的信息对象从pics数组中移除
      _this.uploadfileList.splice(i, 1)
    },
    handleUpload(response, file, fileList) {
      var _this = this
      response.forEach(function (file) {
        var fileArr = {
          filePath: file.completeFilePath,
          fileName: file.fileName,
          fileExt: file.fileExtension,
        }
        _this.uploadfileList.push(fileArr)
      })
    },
    handlePreview(file) {
      console.log(file)
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },

    CancelMessage() {
      // 取消的时候直接设置对话框不可见即可
      this.releaseMesdialog = false
    },
    //获取指定组织机构的普通用户
    herbalSearch() {
      var _this = this
      _this.DoctorData = []
      _this.MessageDomain.GetOrganization(
        function (data) {
          _this.DoctorData = data.data
        },
        function (error) {
          console.log(error)
        }
      )
    },
    search() {
      this.PageIndex = 1
      this.getAllMessage()
    },
    ChangePage(pageIndex) {
      this.PageIndex = pageIndex
      this.getAllMessage()
    },
    getAllMessage() {
      var _this = this
      if (this.activeIndex == '1') {
        _this.MessageDomain.getAllMessage(
          this.PageIndex,
          this.searchForm.keyWord,
          function (data) {
            _this.MessageData = data.data.results
            _this.PageIndex = data.data.pageIndex
            _this.PageSize = data.data.pageSize
            _this.PageTotal = data.data.pageCount
            _this.DataTotal = data.data.dataTotal
          },
          function (error) {
            console.log(error)
          }
        )
      } else {
        _this.MessageDomain.getAllOrgMessage(
          this.PageIndex,
          this.searchForm.keyWord,
          function (data) {
            _this.MessageData = data.data.results
            _this.PageIndex = data.data.pageIndex
            _this.PageSize = data.data.pageSize
            _this.PageTotal = data.data.pageCount
            _this.DataTotal = data.data.dataTotal
          },
          function (error) {
            console.log(error)
          }
        )
      }
    },
    getAllOrgMessage() {
      var _this = this
      _this.MessageDomain.getAllMessage(
        this.PageIndex,
        this.searchForm.keyWord,
        function (data) {
          _this.MessageData = data.data.results
          _this.PageIndex = data.data.pageIndex
          _this.PageSize = data.data.pageSize
          _this.PageTotal = data.data.pageCount
          _this.DataTotal = data.data.dataTotal
        },
        function (error) {
          console.log(error)
        }
      )
    },
    RemoveMessage(messageId) {
      var _this = this
      var Id = this.MessageData[messageId].id
      this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          _this.MessageDomain.DeleteMessage(
            Id,
            function (data) {
              _this.MessageData.splice(messageId, 1)
              _this.$message({
                center: true,
                type: 'success',
                message: '删除成功!',
              })
            },
            function (error) {
              console.log(error)
            }
          )
        })
        .catch(() => {
          this.$message({
            center: true,
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    filterMethod(query, item) {
      return item.userName.indexOf(query) > -1;
    },
    SaveMessage() {
      var _this = this
      var item = _this.platformData
      if (item.title == '' || item.title == null) {
        this.$alert('请填写消息标题！', '提示', {
          confirmButtonText: '确定',
        })
        this.$refs['title'].focus()
        return false
      }
      if (item.content == '' || item.content == null) {
        this.$alert('请填写消息内容！', '提示', {
          confirmButtonText: '确定',
        })
        this.$refs['content'].focus()
        return false
      }
      var publishes = []
      if (item.herbalIdArr) {
        item.herbalIdArr.forEach((element) => {
          publishes.push({ reviceUserId: element })
        })
      }
      if (publishes.length <= 0) {
        this.$alert('请选择选择用户！', '提示', {
          confirmButtonText: '确定',
        })
        return false
      }
        item.organizationId = _this.userInfo.organizationId
        item.createUserId = _this.userInfo.id
      _this.MessageDomain.AddMessage(
        item.title,
        item.content,
        _this.uploadfileList,
        item.organizationId,
        item.createUserId,
        2,
        publishes,
        function (data) {
          _this.$message({
            type: "success",
            message: "发布成功!",
          });
          _this.releaseMesdialog = false
          _this.getAllMessage()
          // location.reload();
        },
        function (error) {
          console.log(error)
        }
      )
    },
  },
}
</script>

<style scoped>
.navbar-expand-lg {
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.navbar {
  position: relative;
  height: 73px;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.header {
  flex: 1;
  text-align: right;
  margin-right: 15px;
}

.messages {
  margin-top: 13px;
  margin-right: 20px;
}

.messages .el-icon-bell {
  font-size: 26px;
}

.iq-search-bar {
  display: inline-block;
  margin-right: 20px;
}

.iq-search-bar .searchbox {
  width: 480px;
  position: relative;
}

.iq-search-bar .searchbox .search-link {
  position: absolute;
  right: 15px;
  top: 2px;
  font-size: 16px;
}

.iq-search-bar .search-input {
  width: 100%;
  padding: 5px 40px 5px 15px;
  border-radius: 10px;
  border: none;
  background: #ceebee;
  line-height: 1.8;
}

.addDrawerBox {
  padding: 0 100px 50px;
  border-radius: 10px;
  height: 100%;
}

.addDrawerwrap {
  padding: 30px;
  background: #ffffff;
  border-radius: 10px;
  height: 100%;
}

.el-drawer__body {
  overflow-y: auto;
}

.drawer-box {
  padding: 0 30px;
}

.drawer-box .top-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.drawer-box .reviceUser-title {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
}

.drawer-box .title {
  text-align: center;
  height: 50px;
  line-height: 50px;
}

.drawer-box .item-img {
  margin-right: 10px;
  display: flex;
  justify-content: flex-start;
  justify-items: center;
  flex-wrap: wrap;
}

.item-img img {
  width: 118px;
  height: 118px;
}
</style>
<style>
.v-modal {
  z-index: 99 !important;
}
</style>
